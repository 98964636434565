import {
  Button,
  Card,
  DatePicker,
  Form,
  Image,
  Input,
  Select,
  Switch,
  Typography,
} from "antd";
import React, { useEffect, useState } from "react";
import has from "lodash/has";
import { UploadOutlined } from "@ant-design/icons";
import styled from "styled-components";
import moment from "moment";
import { generatePassword } from "helpers/helpers";
// import { getPingsUploadURL } from "./pingsAPI";

const { Text } = Typography;
function UserForm({ values, onChange }) {
  const handleChange = (field) => (e) => {
    const value = has(e, "target.value") ? e?.target?.value : e;

    onChange((vals) => ({
      ...vals,
      [field]: value,
    }));
  };
  const inputProps = (field, isSwitch = false) => ({
    onChange: handleChange(field),
    ...(isSwitch ? { checked: values[field] } : { value: values[field] }),
    ...(field === "date"
      ? { value: moment(values[field]) }
      : { value: values[field] }),
  });
  const { password, confirmPassword, gp } = values;
  const matchPassword = password == confirmPassword;
  useEffect(() => {
    if (confirmPassword == gp && confirmPassword != password)
      onChange((vals) => ({
        ...vals,
        confirmPassword: "",
        gp: "",
      }));
  }, [password, confirmPassword]);
  const setGeneratedPassword = () => {
    const pass = generatePassword();
    onChange((vals) => ({
      ...vals,
      password: pass,
      confirmPassword: pass,
      gp: pass,
    }));
  };
  return (
    <Form layout="vertical">
      <Form.Item label="Name">
        <Input {...inputProps("name")} />
      </Form.Item>
      <Form.Item label="Username">
        <Input {...inputProps("username")} />
      </Form.Item>

      {/* <Form.Item label="Password">
        <Input {...inputProps("password")} />
      </Form.Item> */}
      <Form.Item label="Email">
        <Input {...inputProps("email")} />
      </Form.Item>

      <Form.Item label="Area of Responsibility">
        <Input {...inputProps("areaOfResponsibility")} />
      </Form.Item>
      <Form.Item label="Designation">
        <Input {...inputProps("designation")} />
      </Form.Item>
      <Form.Item label="Role">
        <Select
          value={values.role}
          onSelect={handleChange("role")}
          defaultValue={"user"}
        >
          <Select.Option key={"user"} value={"user"}>
            User
          </Select.Option>
          <Select.Option key={"admin"} value={"admin"}>
            Admin
          </Select.Option>
        </Select>
      </Form.Item>

      {!values.id && [
        <Form.Item>
          <Button onClick={setGeneratedPassword} type="ghost">
            Generate Password
          </Button>
        </Form.Item>,
        <Form.Item label="Password">
          <Input {...inputProps("password")} type="password" />
        </Form.Item>,
        <Form.Item label="Confirm Password">
          <Input
            status={matchPassword ? "" : "error"}
            {...inputProps("confirmPassword")}
            type="password"
          />
          {!matchPassword && <Text type={"danger"}>Password do not match</Text>}
        </Form.Item>,
      ]}
      {values.gp && (
        <Form.Item label="Generated Password">
          <Card size="small">
            <Text type="secondary">{values.gp}</Text>
          </Card>
        </Form.Item>
      )}
    </Form>
  );
}

export default UserForm;
