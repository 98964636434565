import { Layout, Result } from 'antd';
import Title from 'antd/lib/typography/Title';
import React from 'react';
import AdamIcon from 'Images/adamIconPen.svg';
import bool from 'prop-types';
import { Link } from 'react-router-dom';
import { StyledContent } from './style';

function PageNotFound({ withHeader, withIcon }) {
  return (
    <Layout style={{ minHeight: (withHeader ? 'calc(100vh - 149px)' : '100vh') }}>
      <StyledContent>
        {withIcon && (
        <div style={{
          display: 'flex', justifyContent: 'center', flexDirection: 'column', opacity: '.5',
        }}
        >
          <img height={100} alt="123" src={AdamIcon} />
          <Title level={2} style={{ display: 'block' }}>
            ADAM
            {' '}
            <span style={{ color: '#00000073' }}>HRIS</span>
          </Title>
        </div>
        )}

        <Result
          status="404"
          title="404"
          subTitle="Sorry, the page you visited does not exist."
          extra={<Link to="/dashboard" type="primary">Back Home</Link>}
        />
      </StyledContent>
    </Layout>
  );
}

PageNotFound.propTypes = {
  withHeader: bool,
  withIcon: bool,
};

PageNotFound.defaultProps = {
  withHeader: false,
  withIcon: false,
};
export default PageNotFound;
