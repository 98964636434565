import React from 'react';
import { LoadingStyle } from './common.styles';

function LoadingIcon() {
  return (
    <LoadingStyle>
      <div />
      <div />
      <div />
      <div />
    </LoadingStyle>
  );
}

export default LoadingIcon;
