export const kvPair = (key, value) => ({ key, value });
export const urlSearchFilter = (
  url = "",
  page = 1,
  search = {},
  filters = {},
  limit = 10
) => {
  const filterQuery = Object.keys(filters)
    .map((k) => `${encodeURI(k)}=${encodeURI(filters[k])}`)
    .join("&");
  const searchQuery = search.search
    ? `&${encodeURI(search.field)}=${encodeURI(search.search)}`
    : "";
  return `${url}?page=${page}&limit=${limit}${searchQuery}&${filterQuery}`;
};

export const generatePassword = () => {
  return Math.random().toString(36).slice(-7) + Math.floor(Math.random() * 10);
};
