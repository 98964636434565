import React, { useState } from "react";
import {
  ActionContainer,
  DashboardDefaultContainer,
} from "Common/common.styles";
import {
  Avatar,
  Card,
  message,
  Modal,
  Table,
  Input,
  Select,
  Badge,
  Popconfirm,
  Button,
  List,
  Form,
  Spin,
  Tooltip,
  Progress,
} from "antd";
import { useMutation, useQuery } from "react-query";
import isEmpty from "lodash/isEmpty";
import Text from "antd/lib/typography/Text";
import Meta from "antd/lib/card/Meta";
import {
  CheckCircleOutlined,
  CloudUploadOutlined,
  DeleteOutlined,
  DownloadOutlined,
  EditOutlined,
  KeyOutlined,
  LockOutlined,
  PlusOutlined,
  StopOutlined,
} from "@ant-design/icons";
import { kvPair } from "helpers/helpers";
import { queryClient } from "AppProviders";
import { useSelector } from "react-redux";
import KAGEAPP_ICON from "Images/logo-icon.png";
import FormItem from "antd/lib/form/FormItem";
import { fetchUsers, updateUsers, updateUserStatus } from "./usersAPI";
import UserForm from "./UserForm";
import {
  createUsers as createAPI,
  deleteUser as deleteAPI,
  // fetchUsers as fetchAPI,
  updateUsers as updateAPI,
} from "./usersAPI";
import ChangePassword from "./ChangePassword";
import UploadUsers from "./UploadUsers";
import { async } from "@firebase/util";

const { Search } = Input;
const { Option } = Select;
const SEARCH_FIELDS = [
  kvPair("name", "Name"),
  kvPair("username", "Username"),
  kvPair("designation", "Designation"),
  kvPair("areaOfResponsibility", "Area of Responsibility"),
];
const MODULE = `User`;
const APP_FILTER = [kvPair("kageapp", "KAGEAPP"), kvPair("ezuna", "EZUNA")];

const headers = [
  {
    key: "name",
    name: "Name",
  },
  {
    key: "areaOfResponsibility",
    name: "Area of Responsibility",
  },
  {
    key: "designation",
    name: "Designation",
  },
  {
    key: "email",
    name: "Email Address",
  },
  {
    key: "role",
    name: "Role",
  },
  {
    key: "username",
    name: "Username",
  },
  {
    key: "gp",
    name: "Password",
  },
];

function Users() {
  const DEFAULT_FIELD = SEARCH_FIELDS[0].key;
  const [search, setSearch] = useState({ field: DEFAULT_FIELD, search: "" });

  const [visible, setVisible] = useState(false);
  const [isExporting, setIsExporting] = useState(false);
  const [pagination, setPagination] = useState({ page: 1, size: 0 });
  const { page, size } = pagination;
  const { user } = useSelector((state) => state.auth);
  const { isLoading, data, error } = useQuery(
    [`fetch${MODULE}`, page, size, search],
    () => fetchUsers(page, size, search),
    {
      onError: (error) => {
        message.error(
          error?.response?.data?.message ||
            "Something went wrong please try again."
        );
      },
    }
  );
  const { mutate: mutateUpdate, isLoading: isUpdating } = useMutation(
    updateUsers,
    {
      onSuccess: () => {
        message.success("Successfuly saved.");
        queryClient.invalidateQueries(`fetch${MODULE}`);
        setVisible(false);
        setValues({});
      },
      onError: (error) => {
        message.error(
          error?.response?.data?.message ||
            "Something went wrong please try again."
        );
      },
    }
  );
  const { mutate: mutateUpdateStatus, isLoading: isUpdatingStatus } =
    useMutation(updateUserStatus, {
      onSuccess: () => {
        message.success("Successfuly saved.");
        queryClient.invalidateQueries(`fetch${MODULE}`);
        setVisible(false);
        setValues({});
      },
      onError: () => {
        message.error("Something went wrong. Please try again.");
      },
    });

  const { mutate: mutateCreate, isLoading: isCreating } = useMutation(
    createAPI,
    {
      onSuccess: () => {
        queryClient.invalidateQueries(`fetch${MODULE}`);
        setVisible(false);
        setValues({});
      },
      onError: (error) => {
        message.error(
          error?.response?.data?.message ||
            "Something went wrong please try again."
        );
      },
    }
  );
  const { mutate: mutateDelete, isLoading: isDeleting } = useMutation(
    deleteAPI,
    {
      onSuccess: () => {
        queryClient.invalidateQueries(`fetch${MODULE}`);
        message.success("Successfuly deleted.");
      },
      onError: (error) => {
        message.error(
          error?.response?.data?.message ||
            "Something went wrong please try again."
        );
      },
    }
  );

  const { results = [], totalResults = 0 } = data?.data || {};
  const [values, setValues] = useState({});
  const onDisableUser = (id, disabled) => {
    mutateUpdateStatus({ id, disabled });
  };
  const renderDisableAction = (row) =>
    !row.disabled ? (
      <Popconfirm
        onConfirm={() => {
          onDisableUser(row.id, true);
        }}
        title="Are you sure you want to disable this user?"
      >
        <Tooltip title={"Disable"}>
          <Button danger icon={<StopOutlined />} type="link" />
        </Tooltip>

        {/* Disable */}
      </Popconfirm>
    ) : (
      <Popconfirm
        onConfirm={() => {
          onDisableUser(row.id, false);
        }}
        title="Are you sure you want to enable this user?"
      >
        <Tooltip title={"Enable"}>
          <Button icon={<CheckCircleOutlined />} type="link" />
        </Tooltip>
      </Popconfirm>
    );
  const columns = [
    {
      title: "ID",
      dataIndex: "id",
      key: "id",
      ellipsis: true,
    },
    {
      title: "Name",
      render: (row) => row.name || "",
    },
    // <Badge color={active ? 'green' : 'grey'} text={active ? 'Published' : 'Draft'} />
    {
      title: "Username",
      render: (row) => row.username || "",
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
      ellipsis: true,
    },
    {
      title: "Area Of Responsibility",
      dataIndex: "areaOfResponsibility",
      key: "areaOfResponsibility",
      ellipsis: true,
    },
    {
      title: "Designation",
      dataIndex: "designation",
      key: "designation",
      ellipsis: true,
    },
    {
      title: "Role",
      dataIndex: "role",
      key: "role",
      width: 100,
    },
    {
      title: "Actions",
      key: "actions",
      // width: 100,
      render: (row) => [
        <Tooltip title="Edit">
          <Button
            icon={<EditOutlined />}
            onClick={() => onClickEdit(row)}
            type="link"
          />
        </Tooltip>,
        <ChangePassword user={row} />,
        user.id === row.id ? null : (
          <Popconfirm
            onConfirm={() => {
              mutateDelete(row.id);
            }}
            title="Are you sure you want to delete this user?"
          >
            <Tooltip title="Delete">
              <Button danger icon={<DeleteOutlined />} type="link" />
            </Tooltip>
          </Popconfirm>
        ),
        user.id === row.id ? null : renderDisableAction(row),
      ],
    },
  ];

  const onFormSubmit = async () => {
    if (values.id) {
      mutateUpdate(values);
    } else {
      mutateCreate({
        ...values,
        gp: values.gp == values.password ? values.gp : "",
      });
    }
  };

  const onClickNew = () => {
    setValues({ role: "user" });
    setVisible(true);
  };

  const onClickEdit = (row) => {
    setValues({ ...row });
    setVisible(true);
  };

  const exportUsers = async () => {
    setIsExporting(true);

    const { data } = await fetchUsers(1, totalResults, search);
    const _users = data.results;

    let csv =
      `data:text/csv;charset=utf-8,` +
      [
        headers.map((h) => h.name).join(","),
        ..._users.map((u) => headers.map((h) => u[h.key] || "").join(",")),
      ].join("\n");
    var encodedUri = encodeURI(csv);
    window.open(encodedUri);
    setIsExporting(false);
    console.log(csv);
  };

  const selectBefore = (
    <Select
      onSelect={(v) => setSearch({ field: v, search: "" })}
      style={{ width: 150 }}
      defaultValue={DEFAULT_FIELD}
      value={search.field}
      className="select-before"
    >
      {SEARCH_FIELDS.map(({ key, value }) => (
        <Option key={key} value={key}>
          {value}
        </Option>
      ))}
    </Select>
  );

  return (
    <DashboardDefaultContainer noBG>
      <ActionContainer>
        <Button icon={<PlusOutlined />} onClick={onClickNew} type="primary">
          {MODULE}
        </Button>
      </ActionContainer>

      <ActionContainer>
        <Search
          addonBefore={selectBefore}
          placeholder="Input search text"
          allowClear
          enterButton="Search"
          size="large"
          onSearch={(s) => {
            setPagination({
              page: 1,
              size: pagination.size,
            });
            setSearch({ field: search.field, search: s });
          }}
        />
        <Button
          icon={<DownloadOutlined />}
          size="large"
          loading={isExporting}
          onClick={exportUsers}
          type="primary"
          style={{ marginLeft: 10 }}
        >
          Export
        </Button>
      </ActionContainer>

      <Table
        loading={isLoading || isUpdating || isUpdatingStatus}
        columns={columns}
        dataSource={results}
        pagination={{
          showTotal: (t, range) => `${range[0]}-${range[1]} of ${t} items`,
          current: page,
          onChange: (e, size) => {
            setPagination({
              page: e,
              size,
            });
          },
          defaultCurrent: page,
          total: totalResults,
        }}
      />
      <UploadUsers file={""} />
      <Modal
        footer={[
          <Button
            key="submit"
            type="primary"
            loading={isUpdating}
            disabled={values.password !== values.confirmPassword && !values.id}
            onClick={onFormSubmit}
          >
            Submit
          </Button>,
        ]}
        onOk={onFormSubmit}
        onCancel={() => setVisible(false)}
        visible={visible}
        title={MODULE}
      >
        <Spin spinning={isUpdating}>
          <UserForm values={values} onChange={setValues} />
        </Spin>
      </Modal>
    </DashboardDefaultContainer>
  );
}

export default Users;
