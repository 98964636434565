/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  user: {},
  tokens: {},
};

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    setAuth: (state, action) => {
      state.user = action.payload.user;
      state.tokens = action.payload.tokens;
    },
    setTokens: (state, action) => {
      state.tokens = action.payload;
    },
    logout: (state) => {
      state.user = {};
      state.tokens = {};
    },

  },

});

export const { setAuth, setTokens, logout } = authSlice.actions;

export default authSlice.reducer;
