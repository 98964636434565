import { LockOutlined } from "@ant-design/icons";
import {
  Button,
  Card,
  Form,
  Input,
  message,
  Modal,
  Row,
  Tooltip,
  Typography,
} from "antd";
import { queryClient } from "AppProviders";
import { generatePassword } from "helpers/helpers";
import React, { PureComponent, useEffect, useState } from "react";
import { useMutation } from "react-query";
import { updateUserPassword } from "./usersAPI";
const MODULE = `User`;
const { Text } = Typography;
const ChangePassword = ({ user }) => {
  const [visible, setVisible] = useState(false);
  const [values, setValues] = useState(user);
  const [gPass, setGPass] = useState(false);
  useEffect(() => {
    setValues({ ...user });
    setGPass(false);
  }, [visible]);

  const { mutate, isLoading } = useMutation(updateUserPassword, {
    onSuccess: () => {
      message.success("Successfuly saved.");
      queryClient.invalidateQueries(`fetch${MODULE}`);
      setVisible(false);
      setValues({});
    },
    onError: () => {
      message.error("Something went wrong. Please try again.");
    },
  });
  const setPassword = () => {
    setGPass(true);
    const pass = generatePassword();
    setValues((vals) => ({
      ...vals,
      password: pass,
      confirmPassword: pass,
      gp: pass,
    }));
  };
  const inputProps = (field, isSwitch = false) => ({
    onChange: (e) => {
      setValues((vals) => ({
        ...vals,
        [field]: e?.target?.value,
      }));
    },
    value: values[field],
  });
  const { password, confirmPassword } = values;
  const matchPassword = password == confirmPassword;
  return (
    <>
      <Tooltip title="Change Password">
        <Button
          onClick={() => setVisible(true)}
          type="link"
          size="small"
          icon={<LockOutlined />}
        ></Button>
      </Tooltip>
      <Modal
        onCancel={() => setVisible(false)}
        title="Change Password"
        visible={visible}
        onOk={() =>
          mutate({
            ...values,
            gp: values.gp == values.password ? values.gp : "",
          })
        }
      >
        <Form layout="vertical">
          <Form.Item label="Username">
            <Input disabled value={user.username} />
          </Form.Item>
          <Form.Item>
            <Button onClick={setPassword} type="ghost">
              Generate Password
            </Button>
          </Form.Item>

          <Form.Item label="Password">
            <Input {...inputProps("password")} type="password" />
          </Form.Item>

          <Form.Item label="Confirm Password">
            <Input
              status={matchPassword ? "" : "error"}
              {...inputProps("confirmPassword")}
              type="password"
            />
            {!matchPassword && (
              <Text type={"danger"}>Password do not match</Text>
            )}
          </Form.Item>

          {values.gp && gPass && (
            <Form.Item label="Generated Password">
              <Card size="small">
                <Text type="secondary">{values.gp}</Text>
              </Card>
            </Form.Item>
          )}
        </Form>
      </Modal>
    </>
  );
};

export default ChangePassword;
