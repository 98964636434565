import React, { useState } from "react";
import { useLocation, Navigate } from "react-router-dom";
import Loader from "components/Loader/Loader";
import { useSelector } from "react-redux";
import isEmpty from "lodash/isEmpty";
// eslint-disable-next-line react/prop-types
function RequireAuth({ children }) {
  const { user } = useSelector((state) => state.auth);
  const [loading, setloading] = useState(true);
  const location = useLocation();
  const isLoggedIn = !isEmpty(user);

  setTimeout(() => {
    setloading(false);
  }, 500);

  if (loading) {
    return <Loader />;
  }
  if (!isLoggedIn) {
    return <Navigate to="/login" state={{ from: location }} replace />;
  }

  return children;
}

export default RequireAuth;
