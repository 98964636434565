import React, { lazy, Suspense } from "react";
import { Route, Routes } from "react-router-dom";
import Loader from "components/Loader/Loader";
import DASHBOARD_ROUTES from "modules/dashboard/dashboardRoute";
import Users from "modules/dashboard/Users";
import RequireAuth from "./RequireAuth";
import PageNotFound from "./components/Loader/PageNotFound";

const Dashboard = lazy(() => import("modules/dashboard/Dashboard"));
const OrganizationSelector = lazy(() =>
  import("modules/OrganizationSelector/")
);

const publicRoutes = [
  {
    path: "/",
    Component: lazy(() => import("modules/LandingPage/LandingPage")),
  },

  {
    path: "login",
    Component: lazy(() => import("modules/Login/Login")),
  },

  {
    path: "counter",
    Component: lazy(() => import("features/counter/Counter")),
  },
];

function Router() {
  return (
    <Suspense fallback={<Loader />}>
      <Routes>
        {publicRoutes.map((route) => (
          <Route
            key={`_${route.path}`}
            path={route.path}
            element={<route.Component />}
          />
        ))}

        <Route
          path="select-organization"
          element={
            <RequireAuth>
              <OrganizationSelector />
            </RequireAuth>
          }
        />

        <Route
          path="dashboard"
          element={
            <RequireAuth>
              <Dashboard />
            </RequireAuth>
          }
        >
          <Route index element={<Users />} />
          {DASHBOARD_ROUTES.map((route) => (
            <Route
              key={`_${route.path}`}
              path={route.path}
              element={<route.Component />}
            />
          ))}
          <Route path="*" element={<PageNotFound withHeader />} />
        </Route>
        <Route path="*" element={<PageNotFound withIcon />} />
      </Routes>
    </Suspense>
  );
}

export default Router;
