import React from 'react';
import './App.css';
// import Dashboard from 'modules/dashboard/Dashboard';
import {
  BrowserRouter,

} from 'react-router-dom';
import AppProviders from 'AppProviders';
import Router from './Router';

function App() {
  return (
    <AppProviders>
      <BrowserRouter>
        <Router />
      </BrowserRouter>
    </AppProviders>
  );

  // App Providers
  // Global style
  // Router
}

export default App;
