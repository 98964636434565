import React, { useState } from "react";
import { CloudUploadOutlined } from "@ant-design/icons";
import CSVReader from "react-csv-reader";
import {
  Button,
  Card,
  List,
  message,
  Progress,
  Typography,
  Upload,
} from "antd";
import Papa from "papaparse";
import TextArea from "antd/lib/input/TextArea";
import { ExportJsonCsv } from "react-export-json-csv";
import { createUsers } from "./usersAPI";
import { generatePassword } from "helpers/helpers";

const { Text } = Typography;

const UploadUsers = () => {
  const [uploading, setUploading] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(1);
  const [total, setTotal] = useState(0);
  const [users, setUsers] = useState([]);
  const [errorUsers, setErrorUsers] = useState([]);
  const beforeUpload = async (file) => {
    const isCSV = file.type === "text/csv";
    // console.log("HELLO");
    if (!isCSV) {
      message.error("You can only upload CSV file!");
      return false;
    }
    console.log("FILETYPE:", file.type);
    setUploading(true);

    try {
      // eslint-disable-next-line no-new
      Papa.parse(file, {
        complete: async ({ data }) => {
          setTotal(data.length);
          if (data.length > 0) {
            const [name, areaOfResponsibility, designation, email, role] =
              data[0];
            if (
              name.toLowerCase() != "name" ||
              areaOfResponsibility.toLowerCase() != "area of responsibility" ||
              designation.toLowerCase() != "designation" ||
              email.toLowerCase() != "email address"
            ) {
              message.error("Csv incorrect format");
              return;
            }
          }
          for (const key in data) {
            if (Object.hasOwnProperty.call(data, key)) {
              const user = data[key];
              if (key != 0) {
                const pass = generatePassword();
                setCurrentIndex(key);
                const [name, areaOfResponsibility, designation, email, role] =
                  user;
                const newUser = {
                  name,
                  areaOfResponsibility,
                  designation,
                  email,
                  role: role.toLowerCase() == "admin" ? "admin" : "user",
                  username: email.split("@")[0],
                  password: pass,
                  gp: pass,
                };
                try {
                  await createUsers(newUser);
                  setUsers((usrs) => [newUser, ...usrs]);
                  console.log(user);
                } catch (e) {
                  // console.log(e, e.response, e.data);
                  setUsers((usrs) => [
                    {
                      ...newUser,
                      error: e?.response?.data?.message || "Connection Error",
                    },
                    ...usrs,
                  ]);

                  console.log(e.message);
                }
              }
            }
          }

          message.success("Finish uploading please export data.");
        },
      });
    } catch (error) {
      //   onError(error);
      console.log(error);
      message.error("Upload failed! Please try again.");
    }

    setUploading(false);

    return false;
  };
  const props = {
    name: "image",
    fileList: [],
    beforeUpload,
  };
  const headers = [
    {
      key: "name",
      name: "Name",
    },
    {
      key: "areaOfResponsibility",
      name: "Area of Responsibility",
    },
    {
      key: "designation",
      name: "Designation",
    },
    {
      key: "email",
      name: "Email Address",
    },
    {
      key: "role",
      name: "Role",
    },
    {
      key: "username",
      name: "Username",
    },
    {
      key: "password",
      name: "Password",
    },
  ];
  const percent = parseInt((currentIndex / (total - 1)) * 100);

  return (
    <Card
      extra={[
        uploading && (
          <Button type="primary" danger>
            Stop
          </Button>
        ),
      ]}
      style={{ width: 500 }}
      title="Upload Users"
    >
      {users.length == 0 ? (
        <div style={{ textAlign: "center" }}>
          <Upload {...props}>
            <Button
              icon={<CloudUploadOutlined />}
              onClick={() => {}}
              type="primary"
            >
              Upload Users
            </Button>
          </Upload>
        </div>
      ) : (
        <div style={{ flex: 1, flexDirection: "row", display: "flex" }}>
          <div
            style={{
              flexDirection: "column",
              display: "flex",
              marginRight: 10,
              flex: 1,
            }}
          >
            <Progress showInfo={false} percent={percent} />
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <Text type="success">
                Success: {users.filter((u) => !u.error).length}
              </Text>
              <Text type="danger">
                Error: {users.filter((u) => u.error).length}
              </Text>
              <Text type="secondary">Total: {users.length}</Text>
            </div>
            <List
              size="small"
              bordered
              style={{
                maxHeight: 300,
                overflowY: "scroll",
                marginTop: 10,
                marginBottom: 10,
              }}
              dataSource={[...users]}
              renderItem={(item) => (
                <List.Item>
                  {item.error ? (
                    <Text type="danger">
                      {item.name} : {item.error}
                    </Text>
                  ) : (
                    <Text type="success">
                      {item.name} - ({item.password})
                    </Text>
                  )}
                </List.Item>
              )}
            />
            {/* <TextArea
              style={{ height: 300, marginTop: 10 }}
              value={
                users
                  .map((u) => `${u.name} - ${u.username} - ${u.password}`)
                  .join("`\n") +
                (errorUsers.length != 0
                  ? `\n===== ERROR ======= \n` +
                    errorUsers
                      .map((u) => `ERROR: ${u.name} ERROR: ${u.error}`)
                      .join("`\n")
                  : "")
              }
            ></TextArea> */}
            {!uploading && (
              <ExportJsonCsv
                style={{
                  marginTop: 10,
                  backgroundColor: "#1890ff",
                  borderColor: "#1890ff",
                  outline: "none",
                  color: "white",
                }}
                headers={headers}
                items={users.filter((u) => !u.error)}
              >
                Export
              </ExportJsonCsv>
            )}
          </div>
        </div>
      )}
    </Card>
  );
};

export default UploadUsers;
