// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_AT_RULE_IMPORT_0___ from "-!../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[5].use[1]!../node_modules/postcss-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[5].use[2]!../node_modules/antd/dist/antd.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".logo {\n  /* height: 32px; */\n  padding: 16px;\n  /* background:#fafafa;  */\n  /* // #000c17; */\n  font-size: 20px;\n  color:rgba(0, 0, 0, 0.85);;\n  text-align: center;\n  border-bottom:1px solid #eff2f5;\n}\n\n.site-layout .site-layout-background {\n  background: #fff;\n}\nbody {\n  font-family: 'Roboto';\n}", "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":"AAEA;EACE,kBAAkB;EAClB,aAAa;EACb,yBAAyB;EACzB,gBAAgB;EAChB,eAAe;EACf,yBAAyB;EACzB,kBAAkB;EAClB,+BAA+B;AACjC;;AAEA;EACE,gBAAgB;AAClB;AACA;EACE,qBAAqB;AACvB","sourcesContent":["@import '~antd/dist/antd.css';\n\n.logo {\n  /* height: 32px; */\n  padding: 16px;\n  /* background:#fafafa;  */\n  /* // #000c17; */\n  font-size: 20px;\n  color:rgba(0, 0, 0, 0.85);;\n  text-align: center;\n  border-bottom:1px solid #eff2f5;\n}\n\n.site-layout .site-layout-background {\n  background: #fff;\n}\nbody {\n  font-family: 'Roboto';\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
