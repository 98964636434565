import React from "react";
import Title from "antd/lib/typography/Title";
import { bool } from "prop-types";
import { Avatar } from "antd";

import KAGEAPP_ICON from "Images/yellow-logo.svg";

function Logo({ withText, ...rest }) {
  return (
    <>
      <img height={100} {...rest} alt="logo" src={KAGEAPP_ICON} />
      {/* <Avatar {...rest} size="large" shape="circle" src={KAGEAPP_ICON} /> */}
      {/* {withText && (
      <Title level={2} style={{ display: 'block' }}>
        KAGE

        <span style={{ color: '#00000073' }}>APP</span>
      </Title>
      )} */}
    </>
  );
}
Logo.propTypes = {
  withText: bool,
};
Logo.defaultProps = {
  withText: false,
};

export default Logo;
