import { urlSearchFilter } from "helpers/helpers";
import pick from "lodash/pick";
import omit from "lodash/omit";

const { default: axiosIns } = require("helpers/axiosInstance");

export const fetchUsers = (page, size, search) =>
  axiosIns.get(urlSearchFilter("/admin/users", page, search, {}, size));
export const updateUsers = (user) =>
  axiosIns.patch(`/admin/users/${user.id}`, omit(user, ["id"]));
export const createUsers = (user) => axiosIns.post(`/admin/users`, user);
export const updateUserPassword = (user) =>
  axiosIns.patch(
    `/admin/users/${user.id}/password`,
    pick(user, ["password", "gp"])
  );

export const deleteUser = (id) => axiosIns.delete(`/admin/users/${id}`);

export const updateUserStatus = (user) =>
  axiosIns.patch(`/admin/users/${user.id}/status`, pick(user, ["disabled"]));
