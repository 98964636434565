import { lazy } from "react";

const DASHBOARD_ROUTES = [
  {
    path: "order-items",
    Component: lazy(() => import("modules/dashboard/OrderItems")),
  },
  {
    path: "promos",
    Component: lazy(() => import("modules/dashboard/Promos")),
  },
  {
    path: "rates",
    Component: lazy(() => import("modules/dashboard/Rates")),
  },
  {
    path: "pepp-rates",
    Component: lazy(() => import("modules/dashboard/PEPPRates")),
  },
  {
    path: "users",
    Component: lazy(() => import("modules/dashboard/Users")),
  },

  {
    path: "settings/site-config",
    Component: lazy(() => import("modules/dashboard/Settings/AboutSettings")),
  },

  {
    path: "settings/devtools",
    Component: lazy(() => import("modules/dashboard/Settings/DevTools")),
  },
];

export default DASHBOARD_ROUTES;
