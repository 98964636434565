import { Layout } from 'antd';
import React from 'react';
import { bool, object } from 'prop-types';
import Logo from 'components/Logo';
import { StyledContent } from './style';
import LoadingIcon from '../../common/LoadingIcon';

function Loader({ withHeader, children }) {
  return (
    <Layout style={{ minHeight: (withHeader ? 'calc(100vh - 149px)' : '100vh') }}>
      <StyledContent>
        <Logo withText />
        <LoadingIcon />
        {children}
      </StyledContent>
    </Layout>
  );
}

Loader.propTypes = {
  withHeader: bool,
  children: object,
};

Loader.defaultProps = {
  withHeader: false,
  children: null,
};
export default Loader;
