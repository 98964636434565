/* eslint-disable no-underscore-dangle */
import { refreshAccessToken } from "redux/auth/authAPI";
import { setTokens, logout } from "redux/auth/authSlice";

const axios = require("axios");
const { store } = require("redux/store");

const axiosIns = axios.create();

// Request interceptor for API calls
axiosIns.interceptors.request.use(
  async (config) => {
    const {
      auth: { tokens },
    } = store.getState();

    config.headers = {
      Authorization: `Bearer ${tokens.access.token}`,
    };
    return config;
  },
  (error) => {
    console.log("err");
    Promise.reject(error);
  }
);

// Response interceptor for API calls
axiosIns.interceptors.response.use(
  (response) => response,
  async (error) => {
    const originalRequest = error.config;

    if (error.response.status === 401 && !originalRequest._retry) {
      const {
        auth: {
          tokens: { refresh },
        },
      } = store.getState();
      originalRequest._retry = true;
      try {
        const { data } = await refreshAccessToken(refresh.token);
        const {
          access: { token },
        } = data;

        store.dispatch(setTokens(data));
        axios.defaults.headers.common.Authorization = `Bearer ${token}`;
        return axiosIns(originalRequest);
      } catch (e) {
        store.dispatch(logout());
      }
    }

    return Promise.reject(error);
  }
);

export default axiosIns;
